import React from 'react'
import PropTypes from 'prop-types'
import MediaLinks from '../MediaLinks'

const BottomFoot = ({
    paymentMethods,
    copyright,
}) => {
    const renderMediaLinks = () => paymentMethods.filter((icon) => !!icon.media).map((icon, index) => {
        const { alt, reference } = icon
        const { media } = icon.media

        return (
            <MediaLinks
                key={index}
                media={media}
                alt={alt}
                reference={reference}
            />
        )
    })

    return (
        <div className={'md:flex bottom-foot'}>
            <div className={'payment-list flex-auto md:text-left icon-link-list'}>
                {renderMediaLinks()}
            </div>
            <div className={'flex-auto md:text-right copyright'}>{copyright}</div>
        </div>
    )
}

BottomFoot.propTypes = {
    paymentMethods: PropTypes.arrayOf(PropTypes.shape({
        media: PropTypes.object,
    })).isRequired,
    copyright: PropTypes.string,
}

export default BottomFoot
