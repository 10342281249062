import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import omit from '@frontastic/common/src/js/helper/omit';
import app from './app';

interface Props {
  params?: any;
  path?: string;
  to?: string;
  href?: string;
  route?: any;
  router: any;
}

const SymfonyLink: FunctionComponent<Props> = (props) => {
  const childPropsToOmit = ['route', 'params', 'router', 'dispatch'];
  const params = { ...props.params };
  let path = props.path;

  if (props.to || props.href) {
    // eslint-disable-next-line no-console
    console.error("Use route parameter with Symfony route ID to link '" + (props.to || props.href) + "'");
  }

  if (!path) {
    childPropsToOmit.push('path'); // do not render empty path attributes

    if (props.router.hasRoute(props.route)) {
      path = props.router.path(props.route, params);
    }
  }

  const childProps = omit(props, childPropsToOmit);

  return (
    <Link to={path || '/__error'} {...childProps}>
      {props.children}
    </Link>
  );
};

SymfonyLink.defaultProps = {
  path: '',
  route: null,
  params: {},
};

export default connect((globalState, props) => {
  return {
    ...props,
    router: app.getRouter(),
  };
})(SymfonyLink);
