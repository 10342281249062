import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import Footer from '../../patterns/molecules/MainFooter'

const SportFooter = ({
    data: {
        titleContactInfo,
        address,
        contactLinks,
        titleAboutUs,
        aboutUsLinks,
        titleService,
        serviceLinks,
        titleLegal,
        legalLinks,
        titleSocial,
        socialLinks,
        paymentMethods,
        copyright,
        reference,
    },
}) => {
    return (
        <Footer
            titleContactInfo={titleContactInfo}
            address={address}
            contactLinks={contactLinks}
            titleAboutUs={titleAboutUs}
            aboutUsLinks={aboutUsLinks}
            titleService={titleService}
            serviceLinks={serviceLinks}
            titleLegal={titleLegal}
            legalLinks={legalLinks}
            titleSocial={titleSocial}
            socialLinks={socialLinks}
            reference={reference}
            paymentMethods={paymentMethods}
            copyright={copyright}
        />
    )
}

SportFooter.propTypes = {
    data: PropTypes.object.isRequired,
    context: PropTypes.object,
}

export default tastify({ translate: true, connect: { wishlist: true, context: true } })(SportFooter)
