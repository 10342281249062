import React from 'react'
import PropTypes from 'prop-types'
import Reference from '@frontastic/catwalk/src/js/component/reference'
import { ArrowRIcon } from '../../atoms/icon'
import PageFolderLink from '@frontastic/catwalk/js/app/pageFolderLink';

const ListLinkHasArrow = ({ links = [], variant, itemVariant }) => {
    return (
        <ul className={variant}>
            {links.map((link, i) => (
                <li key={`${i}-${link.label}`} className={itemVariant}>
                    {link.reference?.type === 'page-folder' ? (
                      <PageFolderLink pageFolder={link.reference?.pageFolder}>{link.item || link.label}</PageFolderLink>
                    ) : (

                        <div className={'read-more-link'}>
                            <Reference reference={link.reference}>
                                <span className={'text'}>
                                    {link.label}
                                </span>
                            </Reference>
                            <ArrowRIcon
                                className={'icon-arrow-r'}
                                fill={'var(--color-current-theme)'}
                                width={20}
                                height={20}
                            />
                        </div>
                    )}
                </li>
            ))}
        </ul>
    )
}

ListLinkHasArrow.propTypes = {
    links: PropTypes.array,
    variant: PropTypes.string,
    itemVariant: PropTypes.string,
}

export default ListLinkHasArrow
