import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import app from '@frontastic/catwalk/src/js/app/app'
import { injectIntl } from 'react-intl'
import MainFoot from './MainFoot'
import BottomFoot from './BottomFoot'
import IconButton from '../../atoms/button/IconButton'
import { ReactComponent as WishlistIcon } from '../../../../icons/sport2000-icons/heart.svg'
import Badge from '../../atoms/badge'

const Footer = ({
    titleContactInfo,
    address,
    contactLinks,
    titleAboutUs,
    aboutUsLinks,
    titleService,
    serviceLinks,
    titleLegal,
    legalLinks,
    titleSocial,
    socialLinks,
    paymentMethods,
    copyright,
    intl,
}) => {
    const { wishListLineItemsCount } = useSelector((state) => {
        const wishListLineItems = state.wishlist?.wishlist?.data?.lineItems || []

        return {
            wishListLineItemsCount: wishListLineItems.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0),
        }
    })

    const addClassIfHaveFilter = () => {
        const targetElement = document.getElementsByClassName('filter-algolia')
        if (!targetElement) {
            return
        }

        if (targetElement && targetElement[0]) {
            const element = document.getElementsByClassName('c-page-foot')[0]
            if (element) {
                element.classList.add('has--filter')
            }
        }
    }

    useEffect(() => {
        addClassIfHaveFilter()
    }, [])

    useEffect(() => {
        wishListLineItemsCount
    }, [wishListLineItemsCount])

    return (
        <div className={'page-foot'}>
            {(wishListLineItemsCount > 0) && (
                <div className={'account-wishlist-mobile'}>
                    <Badge
                        count={wishListLineItemsCount}
                        onClick={() => {
                            app.getRouter().push('Frontastic.Frontend.Master.Account.wishlists', { wishlist: null })
                        }}
                    >
                        <IconButton
                            type={'button'}
                            ariaLabel={intl.formatMessage({ id: 'header.wishlist' })}
                            className={'is--wishlist px-4 outline-none focus:outline-none icon-header'}
                            icon={<WishlistIcon width={24} height={24} />}
                        />
                    </Badge>
                </div>
            )}
            <MainFoot
                titleContactInfo={titleContactInfo}
                address={address}
                contactLinks={contactLinks}
                titleAboutUs={titleAboutUs}
                aboutUsLinks={aboutUsLinks}
                titleService={titleService}
                serviceLinks={serviceLinks}
                titleLegal={titleLegal}
                legalLinks={legalLinks}
                titleSocial={titleSocial}
                socialLinks={socialLinks}
            />
            <BottomFoot
                paymentMethods={paymentMethods}
                copyright={copyright}
            />
        </div>
    )
}
Footer.propTypes = {
    titleContactInfo: PropTypes.string.isRequired,
    address: PropTypes.any,
    contactLinks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    })).isRequired,

    titleAboutUs: PropTypes.string.isRequired,
    aboutUsLinks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    })).isRequired,

    titleService: PropTypes.string.isRequired,
    serviceLinks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    })).isRequired,

    titleLegal: PropTypes.string.isRequired,
    legalLinks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    })).isRequired,

    titleSocial: PropTypes.string.isRequired,
    socialLinks: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.object,
    })).isRequired,

    paymentMethods: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.object,
    })).isRequired,
    copyright: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
}

export default injectIntl(Footer)
