import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default function Badge({
    count, children, displayZeroCount, onClick, className, customCountPosition,
}) {
    return (
        <div
            className={classnames('badge-wrapper', {
                [`${className}`]: className,
            })}
            onClick={onClick}
        >
            {children}
            {(displayZeroCount || count !== 0) && (
                <span className={'badge-count text-xs mt-2'} style={customCountPosition}>{count}</span>
            )}
        </div>
    )
}

Badge.propTypes = {
    /**
     * @property displayZeroCount - indicates, that even if count = 0 - we should show badge anyway
     */
    displayZeroCount: PropTypes.bool,
    /**
     * @property count - indicates count in badge
     */
    count: PropTypes.number,
    /**
     * @property children - normal React children
     */
    children: PropTypes.node.isRequired,
    customCountPosition: PropTypes.any,
    /**
     * @property onClick - click listener for cases when children should have click handler.
     * Needed in order to avoid double click handling.
     */
    onClick: PropTypes.func,
    className: PropTypes.string,
}

Badge.defaultProps = {
    displayZeroCount: false,
    count: 0,
}
