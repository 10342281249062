import React from 'react';

import PropTypes from 'prop-types';
import SymfonyLink from './link';

/**
 * Supports linking to a frontastic page folder
 *
 * @example
 * <PageFolderLink pageFolder={imprintPageFolder}>Imprint</PageFolderLink>
 */
const PageFolderLink = ({ pageFolder, children, ...otherProps }) => {
  return (
    <SymfonyLink {...otherProps} path={pageFolder._url}>
      {children}
    </SymfonyLink>
  );
};

PageFolderLink.propTypes = {
  pageFolder: PropTypes.shape({
    _url: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node,
  params: PropTypes.object,
};

export default PageFolderLink;
