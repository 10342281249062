import React from 'react'
import PropTypes from 'prop-types'
import Reference from '@frontastic/catwalk/src/js/component/reference'
import PageFolderLink from '@frontastic/catwalk/js/app/pageFolderLink';

const List = ({
    links = [], variant, itemVariant, onClick,
}) => {
    return (
        <ul className={variant}>
            {links.map((link, i) => (
                <li key={`${i}-${link.label}`} className={itemVariant} onClick={onClick}>
                    {link.reference?.type === 'page-folder' ? (
                        <PageFolderLink pageFolder={link.reference?.pageFolder}>{link.item || link.label}</PageFolderLink>
                    ) : (
                        <Reference reference={link.reference}>
                            {link.label}
                        </Reference>
                    )}
                </li>
            ))}
        </ul>
    )
}

List.propTypes = {
    links: PropTypes.array,
    variant: PropTypes.string,
    itemVariant: PropTypes.string,
    onClick: PropTypes.func,
}

export default List
