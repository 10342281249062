import React from 'react'
import PropTypes from 'prop-types'
import Image from '@frontastic/catwalk/src/js/image'
import Reference from '@frontastic/catwalk/src/js/component/reference'

const MediaLinks = ({ media, alt, reference }) => (
    <Reference
        reference={reference}
    >
        <Image
            media={media}
            alt={alt}
        />
    </Reference>
)

MediaLinks.propTypes = {
    media: PropTypes.object,
    alt: PropTypes.string,
    reference: PropTypes.any,
}

export default MediaLinks
