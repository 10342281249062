import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Markdown from '@frontastic/catwalk/src/js/component/markdown'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import classnames from 'classnames'
import List from '../../List'
import MediaLinks from '../MediaLinks'
import ListLinkHasArrow from '../../ListLinkHasArrow'
import IconButton from '../../../atoms/button/IconButton'
import { ReactComponent as IconChevronDown } from '../../../../../icons/sport2000-icons/arrow-left.svg'

const MainFoot = ({
    titleContactInfo,
    address,
    contactLinks,
    titleAboutUs,
    aboutUsLinks,
    titleService,
    serviceLinks,
    titleLegal,
    legalLinks,
    titleSocial,
    socialLinks,
}) => {
    const [openItem, setOpenItem] = useState('')
    const isDesktop = useDeviceType() === 'desktop'
    const accordionContent = [
        {
            title: titleAboutUs,
            link: aboutUsLinks,
        },
        {
            title: titleService,
            link: serviceLinks,
        },
        {
            title: titleLegal,
            link: legalLinks,
        },
    ]

    const mediaLink = socialLinks.filter((icon) => !!icon.media).map((icon, index) => {
        const { alt, reference } = icon
        const { media } = icon.media

        return (
            <MediaLinks
                key={index}
                media={media}
                alt={alt}
                reference={reference}
            />
        )
    })

    const toggleAccordion = (item) => {
        if (isDesktop) {
            return
        }

        if (openItem === item) {
            setOpenItem('')

            return
        }

        setOpenItem(item)
    }

    const accordionElements = accordionContent.map((item) => (
        <div
            className={classnames('lg:flex-1 group about-us-group', {
                'is--open': openItem === item.title,
            })}
            key={item.title}
        >
            {item.title && (
                <IconButton
                    ariaLabel={item.title}
                    type={'button'}
                    className={'btn btn-text title flex w-full text-left lg:cursor-default justify-between lg:pointer-events-none'}
                    onClick={() => toggleAccordion(item.title)}
                    icon={!isDesktop && <IconChevronDown width={'16'} height={'16'} />}
                >
                    {item.title}
                </IconButton>
            )}

            <List
                links={item.link}
                variant={'content'}
            />
        </div>
    ))

    return (
        <div className={'lg:flex main-foot'}>
            <div className={'lg:flex-1 group contact-group'}>
                {titleContactInfo && (
                    <div className={'title'}>
                        {titleContactInfo}
                    </div>
                )}

                <div className={'contact-info font-openSans'}>
                    {address && (
                        <Markdown text={address} />
                    )}

                    <ListLinkHasArrow
                        links={contactLinks}
                        variant={'mt-3'}
                        itemVariant={''}
                    />
                </div>
            </div>

            {accordionElements}

            <div className={'lg:flex-1 group social-group'}>
                {titleSocial && (
                    <div className={'title hidden lg:block'}>
                        {titleSocial}
                    </div>
                )}

                <div className={'icon-link-list'}>
                    {mediaLink}
                </div>
            </div>
        </div>
    )
}

MainFoot.propTypes = {
    titleContactInfo: PropTypes.string.isRequired,
    address: PropTypes.any,
    contactLinks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    })).isRequired,
    titleAboutUs: PropTypes.string.isRequired,
    aboutUsLinks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    })).isRequired,
    titleService: PropTypes.string.isRequired,
    serviceLinks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    })).isRequired,
    titleLegal: PropTypes.string.isRequired,
    legalLinks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    })).isRequired,
    titleSocial: PropTypes.string.isRequired,
    socialLinks: PropTypes.arrayOf(PropTypes.shape({
        media: PropTypes.object,
    })),
}

export default MainFoot
